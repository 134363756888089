/* eslint-disable no-nested-ternary */
import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer } from 'react';
// utils
import axios from '../utils/axios';
import { isValidToken, setSession } from '../utils/jwt';
import {
  BlackBenResellerId,
  KgplCorpResellerId,
  MorphyResellerId,
  PromotezapResellerId,
  SukamResellerId,
  UPMResellerId,
  WhatsEraResellerId,
  adwixyResellerId,
  isAdwixy,
  isKglCorp,
  isMorphysms,
  isPromotezap,
  isQruplus,
  isSukum,
  isUPM,
  isVibb,
  isXtechno,
  isblackben,
  qroPlusResellerId,
  whatseraPartnerId,
  xtechnosysResellerId,
} from '../config';

// ----------------------------------------------------------------------

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  },
  LOGIN: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null,
  }),
  REGISTER: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const AuthContext = createContext({
  ...initialState,
  method: 'jwt',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
});

// ----------------------------------------------------------------------

AuthProvider.propTypes = {
  children: PropTypes.node,
};

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = localStorage.getItem('accessToken');

        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken);

          const response = await axios.get('/api/user/profile');
          const { data } = response.data;

          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: true,
              user: data,
            },
          });
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialize();
  }, []);

  const login = async (email, password) => {
    const response = await axios.post('/api/auth/login', {
      email,
      password,
    });
    const { token: accessToken, user } = response.data;

    setSession(accessToken);

    dispatch({
      type: 'LOGIN',
      payload: { user },
    });
  };

  const register = async (name, email, password, mobile) => {
    // eslint-disable-next-line no-nested-ternary
    const resellerId =
      (isMorphysms && MorphyResellerId) ||
      (isSukum && SukamResellerId) ||
      (isKglCorp && KgplCorpResellerId) ||
      (isPromotezap && PromotezapResellerId) ||
      (isAdwixy && adwixyResellerId) ||
      (isUPM && UPMResellerId) ||
      (isblackben && BlackBenResellerId) ||
      WhatsEraResellerId;
    const partnerId =
      (isXtechno && xtechnosysResellerId) ||
      (isUPM && '64f3268ce282251876c0aca0') ||
      (isVibb && '66b6fcfeb342384ba72d6f28') ||
      (isQruplus && qroPlusResellerId) ||
      whatseraPartnerId;
    const response = await axios.post('/api/auth/register', {
      name,
      email,
      password,
      mobile,
      partnerId,
      resellerId,
    });
    const { token: accessToken, user } = response.data;

    setSession(accessToken);

    dispatch({
      type: 'REGISTER',
      payload: { user },
    });
  };

  const logout = async () => {
    setSession(null);
    dispatch({ type: 'LOGOUT' });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        login,
        logout,
        register,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
