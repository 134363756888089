import PropTypes from 'prop-types';
// @mui
import { styled } from '@mui/material/styles';
import { List, Box, ListSubheader } from '@mui/material';
// hooks
import useLocales from '../../../hooks/useLocales';
//
import { NavListRoot } from './NavList';
import useAuth from '../../../hooks/useAuth';
import SvgIconStyle from '../../SvgIconStyle';
import { PATH_DASHBOARD } from '../../../routes/paths';
import { isSendPost, isWhatsEra } from '../../../config';

// ----------------------------------------------------------------------

export const ListSubheaderStyle = styled((props) => <ListSubheader disableSticky disableGutters {...props} />)(
  ({ theme }) => ({
    ...theme.typography.overline,
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    color: theme.palette.text.primary,
    transition: theme.transitions.create('opacity', {
      duration: theme.transitions.duration.shorter,
    }),
  })
);

// ----------------------------------------------------------------------

NavSectionVertical.propTypes = {
  isCollapse: PropTypes.bool,
  navConfig: PropTypes.array,
};
const getIcon = (name) => <SvgIconStyle src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

export default function NavSectionVertical({ navConfig, isCollapse = false, ...other }) {
  const { translate } = useLocales();
  const { user } = useAuth();
  const adminSec = { title: 'admin', path: PATH_DASHBOARD.admin.root, icon: getIcon('ic_admin') };
  const resalerSec = {
    title: 'Dashboard',
    path: PATH_DASHBOARD.dashboard.root,
    icon: getIcon('ic_dashboard'),
    children: [
      { title: 'users', path: PATH_DASHBOARD.dashboard.users },
      { title: 'queries', path: PATH_DASHBOARD.dashboard.queries },
      { title: 'channels', path: PATH_DASHBOARD.dashboard.channels },
    ],
  };

  return (
    <Box {...other}>
      <List disablePadding sx={{ px: 2 }}>
        {user?.isAdmin && (
          <>
            <ListSubheaderStyle
              sx={{
                ...(isCollapse && {
                  opacity: 0,
                }),
              }}
            >
              Admin
            </ListSubheaderStyle>
            <NavListRoot key={adminSec.title + adminSec.path} list={adminSec} isCollapse={isCollapse} />
          </>
        )}
      </List>
      <List disablePadding sx={{ px: 2 }}>
        {user?.isReseller && (
          <>
            <ListSubheaderStyle
              sx={{
                ...(isCollapse && {
                  opacity: 0,
                }),
              }}
            >
              Reseller
            </ListSubheaderStyle>

            <NavListRoot key={resalerSec.title + resalerSec.path} list={resalerSec} isCollapse={isCollapse} />
          </>
        )}
      </List>
      {navConfig.map((group) => (
        <List key={group.subheader} disablePadding sx={{ px: 2 }}>
          <ListSubheaderStyle
            sx={{
              ...(isCollapse && {
                opacity: 0,
              }),
            }}
          >
            {translate(group.subheader)}
          </ListSubheaderStyle>

          {isWhatsEra ||
            (isSendPost && (
              <NavListRoot
                list={{
                  title: 'Apply for Green Tick',
                  path: PATH_DASHBOARD.greenTick.root,
                  icon: getIcon('ic_greentick'),
                }}
                isCollapse={isCollapse}
              />
            ))}
          {group.items.map((list) => (
            <NavListRoot key={list.title + list.path} list={list} isCollapse={isCollapse} />
          ))}
        </List>
      ))}
    </Box>
  );
}
