// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import SvgIconStyle from '../../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  catalogue: getIcon('ic_catalogue'),
  reply: getIcon('ic_reply'),
  button: getIcon('ic_button'),
  list: getIcon('ic_list'),
  attachment: getIcon('ic_attachment'),
  campagain: getIcon('ic_campagain'),
  contacts: getIcon('ic-contacts'),
  dashboard: getIcon('ic_whatsapp'),
  menuItem: getIcon('ic_menu_item'),
  apiIcon: getIcon('ic_api'),
  sheet: getIcon('spreadsheet'),
  hook: getIcon('ic_hook'),
  welcome: getIcon('ic_welcome'),
  lead: getIcon('ic_lead'),
  unsubscribe: getIcon('ic_unsubscribe'),
  zoho: getIcon('ic_zoho'),
};

const navConfig = [
  // GENERAL

  {
    subheader: 'general',
    items: [
      { title: 'channels', path: PATH_DASHBOARD.channels.root, icon: ICONS.dashboard },
      { title: 'contacts', path: PATH_DASHBOARD.contacts.root, icon: ICONS.contacts },
      { title: 'Campaign', path: PATH_DASHBOARD.campaign.root, icon: ICONS.campagain },
      { title: 'Attachment', path: PATH_DASHBOARD.attachment.root, icon: ICONS.attachment },

      { title: 'template', path: PATH_DASHBOARD.template.root, icon: ICONS.button },
    ],
  },
  {
    subheader: 'automation',
    items: [
      { title: 'AutoReply', path: PATH_DASHBOARD.autoReply.root, icon: ICONS.reply },
      { title: 'Welcome Message', path: PATH_DASHBOARD.welcome.root, icon: ICONS.welcome },
      { title: 'hooks', path: PATH_DASHBOARD.hooks, icon: ICONS.hook },
    ],
  },
  {
    subheader: 'management',
    items: [
      { title: 'leads', path: PATH_DASHBOARD.lead, icon: ICONS.lead },
      { title: 'unsubscribe', path: PATH_DASHBOARD.unsubscribers, icon: ICONS.unsubscribe },
      { title: 'invoice', path: PATH_DASHBOARD.businesstools.invoice, icon: ICONS.catalogue },
    ],
  },
  {
    subheader: 'integration',
    items: [
      { title: 'api', path: PATH_DASHBOARD.api.root, icon: ICONS.apiIcon },
      { title: 'Spread Sheet', icon: ICONS.sheet, path: PATH_DASHBOARD.integration.speadSheet },
      // { title: 'zoho books', path: PATH_DASHBOARD.integration.zoho, icon: ICONS.zoho },
    ],
  },
];

export default navConfig;
